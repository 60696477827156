import { RootMapPlugin } from './mapPlugin/RootMapPlugin';
import model from './model';

export default model.createController((controllerParams) => {
  return {
    pageReady: () => {
      const mapPlugin = new RootMapPlugin(controllerParams);
      mapPlugin.init();

      controllerParams.$widget.onPropsChanged((oldProps, newProps) => {
        mapPlugin.handleOnPropsChanged(oldProps, newProps);
      });
    },
    exports: {},
  };
});
