import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  Service,
  QueryServicesResponse,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { BookingsAPI } from '../api/BookingsApi';

export const getService = async ({
  bookingsServiceId,
  flowAPI,
}: {
  bookingsServiceId?: string;
  flowAPI: PlatformControllerFlowAPI;
}): Promise<Service> => {
  const bookingsApi = new BookingsAPI({ flowAPI });
  const serviceResponse: QueryServicesResponse | undefined =
    await bookingsApi.getService({
      serviceId: bookingsServiceId,
    });

  return serviceResponse?.services?.[0]!;
};
