import { defaultLocation } from '../constants/components';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';

export const getEditorMarker = (description) => {
  const { latitude, longitude } = defaultLocation;
  const location = { latitude, longitude };
  return [
    {
      location,
      title: description,
      address: description,
    },
  ];
};

export const getBusinessLocationMarkers = (businessLocation) => {
  return [
    {
      location: getLocation(businessLocation),
      title: getTitle(businessLocation),
      address: getAddress(businessLocation),
    },
  ];
};

export const getMarkers = (service: Service) => {
  const { locations } = service;
  return locations?.map((selectedLocation) => {
    return {
      location: getLocation(selectedLocation),
      title: getTitle(selectedLocation),
      address: getAddress(selectedLocation),
    };
  });
};

export const getLocationType = (service: Service) => {
  return service.locations?.[0].type;
};

const getLocation = (location) => {
  return location.calculatedAddress?.geocode;
};

const getTitle = (location) => {
  return location.business?.name;
};

const getAddress = (location) => {
  return location.calculatedAddress?.formattedAddress;
};
