import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BookingsAPI } from '../api/BookingsApi';

export const getBusinessLocation = async ({
  flowAPI,
}: {
  flowAPI: PlatformControllerFlowAPI;
}) => {
  const bookingsApi = new BookingsAPI({ flowAPI });
  const businessLocation = await bookingsApi.getBusinessLocation();

  return businessLocation;
};
