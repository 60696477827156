import { FlowAPI } from '@wix/yoshi-flow-editor';
import {
  getMarkers,
  getLocationType,
  getEditorMarker,
  getBusinessLocationMarkers,
} from '../formatters/markers';
import { Components } from '../types/components.types';
import {
  LocationType,
  Service,
} from '@wix/ambassador-bookings-services-v2-service/types';

export class MapWidgetView {
  private components: Components;
  private flowAPI: FlowAPI;

  constructor({ components, flowAPI }) {
    this.components = components;
    this.flowAPI = flowAPI;
  }

  renderEmptyStateView() {
    this.components.mapPluginContainer.collapse();
  }

  private setMarkers(markers: any[] | undefined) {
    if (markers && markers.length > 0) {
      this.components.googleMaps.markers = markers;
    } else {
      this.renderEmptyStateView();
    }
  }

  async renderMarkersView(service: Service) {
    const locationType = getLocationType(service);
    switch (locationType) {
      case LocationType.BUSINESS:
        this.setMarkers(getMarkers(service));
        break;
      case LocationType.CUSTOM:
      case LocationType.CUSTOMER:
        this.renderEmptyStateView();
        break;
      default:
        console.warn(`Unsupported location type: ${locationType}`);
        break;
    }
  }

  async renderMarkersBusinessLocationView(businessLocation: any) {
    const marker = getBusinessLocationMarkers(businessLocation);
    this.setMarkers(marker ? marker : undefined);
  }

  renderEditorView(isBusinessLocation: boolean) {
    const key = isBusinessLocation
      ? 'map-plugin.widget.businessLocation'
      : 'map-plugin.widget.serviceLocation';
    const markers = getEditorMarker(this.flowAPI.translations.t(key));
    this.setMarkers(markers);
  }
}
