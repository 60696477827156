import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { locationTypes } from '../../../constants/components';
import { Components } from '../../../types/components.types';
import { RootWidgetControllerParams } from '../../../types/map-plugin.types';
import { getComponents } from '../components';
import { MapWidgetView } from '../../../view/mapWidget';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { reportWidgetPageLoaded } from '../../../services/BiLogger/BiLogger';
import { getService } from '../../../actions/getService';
import { getBusinessLocation } from '../../../actions/getBusinessLocation';

type IWidgetControllerConfig = RootWidgetControllerParams['controllerConfig'];
export class RootMapPlugin {
  public controllerConfig: IWidgetControllerConfig;
  public wixSdkAdapter: WixOOISDKAdapter;
  public flowAPI: PlatformControllerFlowAPI;
  public components: Components;
  public MapWidgetView: MapWidgetView;
  public biLogger;

  constructor(public readonly controllerParams: RootWidgetControllerParams) {
    this.controllerConfig = controllerParams.controllerConfig;
    this.wixSdkAdapter = new WixOOISDKAdapter(
      controllerParams.controllerConfig.wixCodeApi,
      controllerParams.controllerConfig.platformAPIs,
      controllerParams.controllerConfig.appParams,
      controllerParams.controllerConfig.compId,
      controllerParams.flowAPI.experiments,
    );
    this.flowAPI = controllerParams.flowAPI;
    this.components = getComponents(controllerParams.$w);
    this.MapWidgetView = new MapWidgetView({
      components: this.components,
      flowAPI: this.flowAPI,
    });
    this.biLogger = controllerParams.flowAPI.essentials
      .biLoggerFactory()
      .logger();
  }

  private async reportPluginLoaded(bookingsServiceId) {
    const { isEditor, isEditorX } = this.controllerParams.flowAPI.environment;
    reportWidgetPageLoaded(this.biLogger, {
      is_over_editor: isEditor || isEditorX,
      serviceId: bookingsServiceId,
      widget_name: 'map_plugin',
      origin: 'map_plugin',
    });
  }

  private getServiceId() {
    return this.controllerParams.$widget.props.bookingsServiceId;
  }

  private getLocationType() {
    return this.controllerParams.$widget.props.locationType;
  }

  private isBusinessLocation() {
    return this.getLocationType() === locationTypes.BUSINESS_LOCATION;
  }

  private isEditorOrEditorX() {
    return (
      this.flowAPI.environment.isEditor || this.flowAPI.environment.isEditorX
    );
  }

  private isPreviewOrViewer() {
    return (
      this.flowAPI.environment.isPreview || this.flowAPI.environment.isViewer
    );
  }

  private async initPlugin(bookingsServiceId: string) {
    if (this.isBusinessLocation()) {
      const businessLocation = await getBusinessLocation({
        flowAPI: this.flowAPI,
      });
      this.MapWidgetView.renderMarkersBusinessLocationView(businessLocation);
    } else {
      const service = await getService({
        bookingsServiceId,
        flowAPI: this.flowAPI,
      });
      this.MapWidgetView.renderMarkersView(service);
    }
  }

  private shouldReloadPlugin(
    oldServiceId: string,
    newServiceId: string,
    oldLocation: string,
    newLocation: string,
  ): boolean {
    return newServiceId !== oldServiceId || newLocation !== oldLocation;
  }

  public async handleOnPropsChanged(oldProps, newProps): Promise<void> {
    const oldServiceId = oldProps.bookingsServiceId;
    const newServiceId = newProps.bookingsServiceId;
    const oldLocation = oldProps.locationType;
    const newLocation = newProps.locationType;

    if (
      this.shouldReloadPlugin(
        oldServiceId,
        newServiceId,
        oldLocation,
        newLocation,
      )
    ) {
      this.components.googleMaps.hide();
      const bookingsServiceId = this.getServiceId();
      const isPreviewOrViewer = this.isPreviewOrViewer();

      this.reportPluginLoaded(bookingsServiceId);

      if (isPreviewOrViewer && newServiceId && newServiceId !== oldServiceId) {
        await this.initPlugin(bookingsServiceId);
      }

      if (newLocation && newLocation !== oldLocation) {
        this.init();
      }

      this.components.googleMaps.show();
    }
  }

  private initEditorView() {
    this.reportPluginLoaded('');
    this.MapWidgetView.renderEditorView(this.isBusinessLocation());
  }

  public init(): void {
    if (this.isEditorOrEditorX()) {
      this.initEditorView();
      return;
    }
  }
}
