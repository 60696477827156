import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { locationTypes } from '../../constants/components';

export default createBlocksModel({
  widgetName: 'Widget1',
  props: {
    bookingsServiceId: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    locationType: {
      type: WidgetPropertyType.STRING,
      defaultValue: locationTypes.SERVICE_LOCATION,
    },
  },
  methods: {},
  events: {},
});
