export enum EmptyStateType {
  SERVICE_NOT_FOUND = 'SERVICE_NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
  GET_BOOKING_DETAILS_ERROR = 'GET_BOOKING_DETAILS_ERROR',
  GET_BOOKING_DETAILS_ACCESS_DENIED = 'GET_BOOKING_DETAILS_ACCESS_DENIED',
  SESSION_CAPACITY_EXCEEDED = 'SESSION_CAPACITY_EXCEEDED',
  SCHEDULE_CAPACITY_EXCEEDED = 'SCHEDULE_CAPACITY_EXCEEDED',
  SLOT_NOT_AVAILABLE = 'SLOT_NOT_AVAILABLE',
  BOOKING_POLICY_VIOLATION = 'BOOKING_POLICY_VIOLATION',
  CANNOT_FETCH_ECOM_ROLLOUT_STATUS = 'CANNOT_FETCH_ECOM_ROLLOUT_STATUS',
}

export type Optional<T> = T | undefined;
