import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const manageWarmupData: <T>(
  callback: () => Promise<T> | T,
  key: string,
  flowAPI: PlatformControllerFlowAPI,
) => Promise<T> = async (callback, key, flowAPI): Promise<any> => {
  const isSSR = flowAPI.environment.isSSR;
  const compId = flowAPI.controllerConfig.compId;
  const warmupData = flowAPI.controllerConfig.wixCodeApi.window.warmupData;

  const cacheKey = `${compId}_${key}`;
  if (!isSSR) {
    const cache = warmupData.get(cacheKey);
    if (cache) {
      return cache;
    }
  }
  const data = await callback();
  if (isSSR) {
    warmupData.set(cacheKey, data);
  }

  return data;
};
