
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  
            
import wrapController0 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller0 from '/home/builduser/work/f35f652dcafd0a82/packages/map-plug-in/src/components/Widget1/viewer.controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/f35f652dcafd0a82/packages/map-plug-in/src/components/Widget1/model.ts';
            var controllerExport0 = _controllerExport0;
            

  
  var importedApp = {};


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { observable } from 'mobx';
    var velocycleMobx = { initState, initBind, observable };
    


  

    import { blocksControllerService } from '@wix/yoshi-flow-editor/runtime/esm/blocks/controllerService'
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = null;

  var experimentsConfig = {"scopes":["map-plugin"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/f35f652dcafd0a82/packages/map-plug-in/src/assets/locales/messages_en.json","availableLanguages":["en"]};

  var defaultTranslations = {"map-plugin.widget.serviceLocation":"Service Location","map-plugin.widget.businessLocation":"Business Location","map-plugin.widget.display-name":"Map Plugin","map-plugin.gfpp.main-action1":"Manage Locations","map-plugin.panel.settings.title":"Map Plugin","map-plugin.panel.settings.location-options.title":"What should the map show?","map-plugin.panel.settings.location-options.service-location":"Service location","map-plugin.panel.settings.location-options.business-location":"Business location","map-plugin.panel.settings.edit-your-locations":"You can add or edit your business locations.","map-plugin.panel.settings.button.business-info.label":"Go to Business info","map-plugin.panel.settings.empty-state.label":"To display your location on the map, you need to create a service for your site.","map-plugin.panel.settings.empty-state.button.new-service.label":"Add a New Service"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/f35f652dcafd0a82/packages/map-plug-in/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "map-plug-in",
    biConfig: null,
    appName: "New Yoshi Blocks App",
    appDefinitionId: "c5fedde3-63c7-4041-a5fb-c77bbe6308dc",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"scopes":["map-plugin"],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/map-plug-in/src/components/Widget1/viewer.controller.ts",
          appName: "New Yoshi Blocks App",
          appDefinitionId: "c5fedde3-63c7-4041-a5fb-c77bbe6308dc",
          projectName: "map-plug-in",
          componentName: "Widget1",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "c5fedde3-63c7-4041-a5fb-c77bbe6308dc-pkwg7" }],
    false);

    export const createControllers = _createControllers
